import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pine-tree-exercises"
    }}>{`Pine Tree Exercises`}</h1>
    <h2 {...{
      "id": "zhan-zhuang-hug-a-tree"
    }}>{`Zhan Zhuang: Hug A Tree`}</h2>
    <ul>
      <li parentName="ul">{`Legs: Wuji, Taiji, Ma Bu.`}</li>
      <li parentName="ul">{`Stand for 5 minutes.`}</li>
      <li parentName="ul">{`Opposite Arm to Leg.`}</li>
    </ul>
    <h2 {...{
      "id": "part-1"
    }}>{`Part 1`}</h2>
    <h3 {...{
      "id": "eagle-takes-flight"
    }}>{`Eagle Takes Flight`}</h3>
    <ul>
      <li parentName="ul">{`Legs: V - Taiji.`}</li>
      <li parentName="ul">{`Stretch Up.`}</li>
      <li parentName="ul">{`Rolling through the foot.`}</li>
    </ul>
    <h3 {...{
      "id": "little-fan-big-fan"
    }}>{`Little Fan, Big Fan`}</h3>
    <ul>
      <li parentName="ul">{`Wuji, Taiji, Ma Bu.`}</li>
    </ul>
    <h3 {...{
      "id": "shoulder-twists"
    }}>{`Shoulder Twists`}</h3>
    <ul>
      <li parentName="ul">{`Legs: hip width and a half.`}</li>
      <li parentName="ul">{`Roll spine down.`}</li>
      <li parentName="ul">{`Make circles.`}</li>
    </ul>
    <h3 {...{
      "id": "swan-wakes-up"
    }}>{`Swan Wakes Up`}</h3>
    <ul>
      <li parentName="ul">{`Legs: V - Taiji`}</li>
      <li parentName="ul">{`Palms Up`}</li>
      <li parentName="ul">{`Move from shoulder blades.`}</li>
      <li parentName="ul">{`Rolling through the foot.`}</li>
    </ul>
    <h3 {...{
      "id": "little-fan-big-fan-1"
    }}>{`Little Fan Big Fan`}</h3>
    <ul>
      <li parentName="ul">{`Wuji, Taiji, Mabu.`}</li>
    </ul>
    <h3 {...{
      "id": "u-turn-up"
    }}>{`U-Turn Up`}</h3>
    <ul>
      <li parentName="ul">{`Legs: hip width and a half.`}</li>
      <li parentName="ul">{`Roll spine down.`}</li>
      <li parentName="ul">{`Scoop and straight.`}</li>
    </ul>
    <h3 {...{
      "id": "little-fan-big-fan-2"
    }}>{`Little Fan Big Fan`}</h3>
    <ul>
      <li parentName="ul">{`Wuji, Taiji, Ma Bu.`}</li>
    </ul>
    <h3 {...{
      "id": "bend-and-straighten"
    }}>{`Bend and Straighten`}</h3>
    <ul>
      <li parentName="ul">{`Legs: hip width and a half.`}</li>
      <li parentName="ul">{`Roll spine down.`}</li>
      <li parentName="ul">{`Bend elbows and knees.`}</li>
    </ul>
    <h2 {...{
      "id": "part-2"
    }}>{`Part 2`}</h2>
    <h3 {...{
      "id": "sun-worship"
    }}>{`Sun Worship`}</h3>
    <h3 {...{
      "id": "plies-triangle-fingers"
    }}>{`Plies: Triangle Fingers`}</h3>
    <h3 {...{
      "id": "kicks-opposite-arm-to-leg"
    }}>{`Kicks: Opposite Arm to Leg`}</h3>
    <h3 {...{
      "id": "kiss-knee"
    }}>{`Kiss Knee`}</h3>
    <h3 {...{
      "id": "bend-and-straighten-1"
    }}>{`Bend and Straighten`}</h3>
    <h3 {...{
      "id": "chinese-boxer-stretch"
    }}>{`Chinese Boxer Stretch`}</h3>
    <h3 {...{
      "id": "beng-and-straighten"
    }}>{`Beng and Straighten`}</h3>
    <h3 {...{
      "id": "beat-body"
    }}>{`Beat Body`}</h3>
    <h3 {...{
      "id": "rub-kidneys"
    }}>{`Rub Kidneys`}</h3>
    <h3 {...{
      "id": "hugs"
    }}>{`Hugs`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      